<template>
  <div
    v-if="cartsData.length"
    class="shopping-mall__wrap"
  >
    <shopping-bag-options 
      :carts-info="cartsData" 
      :carts-mall-info="cartsMallInfo" 
      :quick-ship-info="quickShipInfo"
      :mall-code="mallCode"
      from-type="shoppingMall"
      @insurance-check-opt="insuranceCheckOpt"
    />

    <shipping-options
      :mall-code="mallCode"
      :shipping-methods="shippingMethods"
      :carts-data="cartsData"
      :quick-ship-info="quickShipInfo"
      @insurance-check-opt="insuranceCheckOpt"
      @handle-shipping-select="handleShippingSelect"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import shippingOptions from 'public/src/pages/checkout/vue_tpls/shipping_method/ShippingOptions.vue'
import shoppingBagOptions from './shopping_bag_options.vue'
import { transfromAndCutImg } from 'public/src/services/resource/index'

export default {
  name: 'ShoppingMall',
  components: {
    shippingOptions,
    shoppingBagOptions,
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    cartsData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('checkout', ['checkout', 'mallShippingMethods', 'locals', 'defaultShipping']),
    quickShipInfo() {
      const { mall_list = [] } = this.mallShippingMethods || {}
      const filterShip =  mall_list?.find(item => item.mall_code == this.mallCode) || {}
      return { desc_quick_ship_time: filterShip?.desc_quick_ship_time || '', quick_ship_time: filterShip?.quick_ship_time || '' }
    },
    shippingMethods() {
      const { mall_list = [] } = this.mallShippingMethods || {}
      const filterShip =  mall_list.find(item => item.mall_code == this.mallCode) || {}
      return filterShip?.shipping_methods || []
    },
    cartsMallInfo() {
      return this.checkout?.results?.carts?.mall_list?.find(item => item.mall_code == this.mallCode) || {}
    },
  },
  provide() {
    return {
      cutImg: this.cutImg,
    }
  },
  methods: {
    insuranceCheckOpt(mall_code) {
      this.$emit('insuranceCheckOpt', mall_code)
    },
    handleShippingSelect({ item, index, mallCode, transport_type }) {
      this.$emit('handleShippingSelect', { item, index, mallCode, transport_type })
    },
    /** 图片裁切 */
    cutImg (imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.locals || {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }

      return transfromAndCutImg(cutData)
    },
  }
}
</script>

<style lang="less" scoped>
.shopping-mall__wrap {
  background: #fff;
}
</style>
