<template>
  <div
    class="apple-pay-btn"
    :style="{
      'justify-content': propsStyle.justifyContent,
      'display': propsStyle.display,
      'width': propsStyle.width
    }"
  >
    <apple-pay-button
      :style="{
        '--apple-pay-button-width': propsStyle.width,
        '--apple-pay-button-height': propsStyle.height
      }"
      buttonstyle="black"
      type="pay"
      :locale="applePayBtnLocale"
      @click="handleBtnClick"
    />
  </div>
</template>
<script>
import { asyncLoadFile } from '@shein/common-function'
const IS_SSR = typeof window == 'undefined'
const { lang } = IS_SSR ? {} : window.gbCommonInfo

// 因apple按钮语种与shein侧的语种规则不一致，为了获取当前站点国家对应的语种，查询当前用户所在国家
const applePayLocal = {
  ar: 'ar-AB',
  ca: 'ca-ES',
  eur: 'cs-CZ',
  // eur: 'da-DK',
  de: 'de-DE',
  'el-gr': 'el-GR',
  au: 'en-AU',
  // uk: 'en-GB',
  us: 'en-US',
  es: 'es-ES',
  mx: 'es-MX',
  // eur: 'fi-FI',
  ca: 'fr-CA',
  fr: 'fr-FR',
  il: 'he-IL',
  in: 'hi-IN',
  // de: 'hr-HR',
  id: 'id-ID',
  it: 'it-IT',
  jp: 'ja-JP',
  kr: 'ko-KR',
  my: 'ms-MY',
  roe: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  euqs: 'ro-RO',
  ru: 'ru-RU',
  eur: 'sk-SK',
  se: 'sv-SE',
  th: 'th-TH',
  asia: 'tr-TR',
  uk: 'uk-UA',
  vn: 'vi-VN',
  hk: 'zh-HK',
  tw: 'zh-TW'
}

export default {
  name: 'ApplePayBtn',
  props: {
    propsStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loadingStatus: false,
      applePayBtnLocale: 'en-US'
    }
  },
  mounted() {
    // 使用 Vue.nextTick 确保 DOM 更新完成
    this.$nextTick(() => {
      asyncLoadFile({
        label: 'script',
        attrs: {
          src: 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js'
        },
        inBody: false
      })
        .then(() => {
          this.loadingStatus = false
          // 找到所有标签名为 apple-pay-button 的元素
          let applePayButtons = document.getElementsByTagName('apple-pay-button')
          // 遍历所有找到的元素
          for (let i = 0; i < applePayButtons.length; i++) {
            let button = applePayButtons[i]
            button.removeAttribute('hidden')
            button.removeAttribute('aria-hidden')
            button.style.display = 'block'
            if (this.propsStyle.direction) {
              button.style.direction = this.propsStyle.direction
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.loadingStatus = false
        })
    })

    this.applePayBtnLocale = applePayLocal[lang] || 'en-US'
  },

  methods: {
    handleBtnClick() {
      this.$emit('handleBtnClick', true)
    },
  }
}
</script>
<style scoped>
.apple-pay-btn {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 100%;
  --apple-pay-button-border-radius: 0px;
  --apple-pay-button-padding: 5px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
