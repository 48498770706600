<template>
  <div class="checkout-activity-popover__tips">
    <div class="checkout-activity-popover__tips-content">
      <div 
        ref="activityPopoverTipsDescRef"
        class="checkout-activity-popover__tips-des"
        :class="[!!timeTips ? 'two-rows-ellipsis' : 'three-rows-ellipsis']"
      >
        <Icon
          v-if="icon.name"
          class="checkout-activity-popover__tips-icon"
          :name="icon.name"
          :color="icon.color"
          :size="icon.size"
        />
        <span
          ref="descriptionRef"
          class="description"
          v-html="description"
        ></span>
      </div>
      <div 
        v-if="timeTips"
        class="checkout-activity-popover__tips-timedown"
      >
        {{ timeTips }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'

import { template, CountDown } from '@shein/common-function'

export default {
  name: 'ActivityTips',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    },
    timedown: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    // 倒计时文案
    timeTips () {
      if (!this.timer) return ''
      const { H, M, S } = this.timer?.timeObj || {}
      return this.template(
        `${H}:${M}:${S}`,
        this.language.SHEIN_KEY_PWA_29054
      )
    },
  },
  watch: {
    timedown: {
      handler(newVal, oldVal) {
        this.initTimer()
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      },
      immediate: true
    },
    description: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      }
    }
  },
  methods: {
    template,
    initTimer() {
      let endTime = +this.timedown
      if (!endTime) return
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    /**
     * @description: 用于处理descript的字号
     * @return {*}
     */    
    handleDescriptionFontSize() {
      if(typeof window == 'undefined') return
      this.$nextTick(() => {
        setTimeout(()=>{
          this.$refs.activityPopoverTipsDescRef.style.whiteSpace = 'nowrap'

          const rootFontSize = window.getComputedStyle(document.documentElement).fontSize.replace('px', '') // 根元素的px大小
          const descriptionWidth = this.$refs.activityPopoverTipsDescRef?.offsetWidth || 0
          const rowGapDistance = (32 / 37.5) * rootFontSize // 32是375设计稿所有的横向间距，包括气泡内边距、图片和文案的间距
          const leftImgWrapperWidth = document.querySelector('.j-activity-left')?.offsetWidth || 0
          
          // 设计稿最大宽度260整个气泡的最大宽度，这里是进行像素计算，这块需要去掉小数，因为上面的offsetWidth取得是整数，否则下面在个别机型比较的时候总会有误差
          const maxWidth = (260 - rowGapDistance - leftImgWrapperWidth)?.toFixed(0)
          
          // 搭配属性white-space，如果是单行并且没有超过最大的宽度，则你不需要执行下面的省略号以及字号的逻辑
          if (descriptionWidth < maxWidth) {
            this.$refs.activityPopoverTipsDescRef.style.whiteSpace = 'nowrap'
            return
          }

          if (descriptionWidth >= maxWidth) {
            this.$refs.activityPopoverTipsDescRef.style.whiteSpace = 'normal'
            this.$refs.activityPopoverTipsDescRef.style.width = `${maxWidth / rootFontSize}rem`
          }

          const descriptionHeight = this.$refs.activityPopoverTipsDescRef?.scrollHeight || 0
          const maxHeight = 27 // 文本的高度

          if(descriptionHeight > maxHeight) {
            this.$refs.descriptionRef.style.fontSize = '10px'
          } else {
            this.$refs.descriptionRef.style.fontSize = '12px'
          }
        }, 0)
      })
    }
  }
}
</script>

<style lang="less">
.checkout-activity-popover__tips {
  .margin-l(16/75rem);
}
.checkout-activity-popover__tips-icon {
  .margin-r(4/75rem);
}
.checkout-activity-popover__tips-content {
  line-height: 1;
}
.checkout-activity-popover__tips-des {
  .description {
    .font-dpr(24px);
    color: @sui_color_white;

    em {
      font-weight: 700;
      color: @sui_color_discount;
    }

    .prime {
      color: @sui_color_club_rosegold_dark3;
    }
    .flash {
      color: @sui_color_flash;
    }
  }
}
.two-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.three-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.checkout-activity-popover__tips-timedown {
  .font-dpr(20px);
  color: @sui_color_guide;
  margin-top: 4/75rem;
}
</style>
