<template>
  <div class="checkout-activity-popover__promotion">
    <GoodsList 
      v-if="promotionCarts.length"
      class="j-activity-left"
      :language="language"
      :goodsList="promotionCarts"
    />

    <Tips 
      v-if="description && description.length"
      :language="language"
      :icon="icon"
      :description="description"
      :timedown="countDownData"
    />
  </div>
</template>

<script>
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'

import { template } from '@shein/common-function'
import { iconConfig } from './config'

export default {
  name: 'PromotionPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    integrityPromotionInfo: {
      type: Object,
      default: () => {}
    },
    promotionList: {
      type: Array,
      default: () => []
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    flashSaleGroupList: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
    isSinglePromotion() {
      return this.promotionList.length <= 1
    },
    promotionCarts() {
      const promotion_id_list = this.promotionList.map(item => item.id) || []
      return this.carts.filter(item => {
        const product_promotion_info = item.product?.product_promotion_info || []
        const SamePromotion = product_promotion_info.find(promoption => promotion_id_list.includes(promoption.promotion_id))
        return !!SamePromotion
      })
    },
    icon() {
      if(this.isSinglePromotion) {
        const type = this.promotionList[0]?.icon || ''
        return type ? iconConfig[type] : {}
      } else {
        return {}
      }
    },
    description() {
      if(this.isSinglePromotion) {
        return this.promotionList[0]?.tip
      } else {
        const discount = this.checkout.results?.carts?.cartInfo?.total_discount_price?.amountWithSymbol
        return this.template(`<em>${discount}</em>`, this.language.SHEIN_KEY_PWA_29171)
      }
    },
    promotionDiscountInfo () {
      let discountInfo = this.checkout.results?.carts?.cartInfo.promotion_discount_info || []
      return discountInfo
    },
    // 多商品时取最近的倒计时
    countDownData () {
      if(!this.isSinglePromotion || !this.promotionCarts.length) return ''

      const endTimeList = []
      this.promotionCarts?.forEach(item => {
        if(item.promotionTypeIds?.some(i => [3, 8, 11].includes(i)) || (item.promotionTypeIds?.includes(10) &&  [1, 4, 5].includes(Number(item.flashType)))){
          let promotion = item.product.product_promotion_info.find(_item => [3, 8, 10, 11].includes(+_item.type_id))
          if(promotion) {
            let targetPromotion = this.integrityPromotionInfo?.[promotion.promotion_id]
            if(targetPromotion?.is_count_down){
              endTimeList.push(+targetPromotion.end_time)
            }
          }
        }
      })
      return endTimeList.sort((a, b) => a - b)?.[0] || ''
    },
  },
  methods: {
    template,
  }
}
</script>
