<template>
  <li class="activity-popover__goods-item">
    <div class="goods-item__img">
      <img
        class="i-img"
        :src="transformImg({ img: item.product.goods_img })"
      />
      <div
        v-if="item.product.unit_discount > 0"
        class="new-discount"
        :class="{
          'fs-discount': isFlashDiscount,
          'fs-new-discount': isFlashNewDiscount,
          'premium-member-discount': isPremiumMember
        }"
        aria-hidden="true"
      >
        <span>-{{ item.product.unit_discount }}%</span>
      </div>

      <div
        v-if="outQuantity > 0"
        class="goods-item__mask"
      >
        <div class="goods-item__mask-detail">
          +{{ outQuantity }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { transformImg } from '@shein/common-function'

export default {
  name: 'GoodsItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    outQuantity: {
      type: Number,
      default: 0
    },
  },
  computed: {
    isFlashDiscount() {
      const { promotionTypeIds, flashType } = this.item || {}
      // 闪购活动
      const isFlash = promotionTypeIds?.includes(10)
      // flashType取值：1（普通闪购），2（新人闪购），3（特殊闪购） 4-5 （专区闪购）
      // 常规闪购
      const isNormalFlash = isFlash && flashType == 1
      // 专区闪购
      const isSpecialFlash = isFlash && [4, 5].includes(flashType)
      // 直播闪购
      const isLiveFlash = promotionTypeIds?.includes(11)
      return isNormalFlash || isSpecialFlash || isLiveFlash
    },
    isFlashNewDiscount() {
      const { promotionTypeIds, flashType } = this.item || {}
      return promotionTypeIds?.includes(10) && [2, 3].includes(flashType)
    },
    isPremiumMember () {
      return this.item?.promotionTypeIds?.includes(29)
    },
  },
  methods: {
    transformImg,
  }
}
</script>

<style lang="less" scoped>
.mixin-discount-tag() {
  position: absolute;
  right: auto;
  text-align: center;
  color: @color_white_mirror;
  direction: ltr;
  .font-dpr(18px);
  padding: 1/75rem 4/75rem;
  line-height: 1.35;
  top: 0;
  .left(0);
  background: #333;
}

.activity-popover__goods-item {
  .margin-r(8/75rem);
  &:last-child {
    .margin-r(0);
  }
  .goods-item__img {
    position: relative;
    width: 76/75rem;
    height: 76/75rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .new-discount {
    .mixin-discount-tag();
    &.fs-discount {
      background:@sui_color_flash;
      color: #333;
    }
    &.premium-member-discount {
      background: #FFE2D3;
      color: #873C00;
    }
    &.fs-new-discount {
      background: @sui_color_discount;
      color: @sui_color_white;
    }
  }
  .goods-item__mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.70);
    top: 0;
    .left(0);
    width: 100%;
    height: 100%;
    .goods-item__mask-detail {
      position: absolute;
      .font-dpr(24px);
      color: #fff;
      font-weight: 600;
      text-align: center;
      top: 50%;
      .left(50%);
      transform: translate(-50%, -50%);
    }
  }
}
</style>
