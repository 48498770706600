<template>
  <ul
    v-ada-radio-group
    class="mche-edit-ship"
  >
    <ShippingListItem 
      v-for="(item, index) in shippingMethods"
      :key="index"
      :item="item"
      :index="index"
      :isClickMore="isClickMore"
      :checked="checked"
      :mallCode="mallCode"
      :cartsData="cartsData"
      :descQuickShipTime="descQuickShipTime"
      :isAllQuickShip="isAllQuickShip"
      :isQuickShipTitle="isQuickShipTitle"
      :primeCouponCount="primeCouponCount"
      :morePosition="positionConfig.position"
      :quickShipInfo="quickShipInfo"
      :addItemContentInfo="addItemContentInfo"
      :default-shipping-item="defaultShippingItem"
      :defaultSelectedIndex="defaultSelectedIndex"
      :class="[{'mshe-ship-disabled': !item.is_available}, {'last-shipping-item': (index + 1) == shippingMethods.length}]"
      @handleSelect="handleSelect"
      @showDayPercentList="showDayPercentList"
    />
    <div 
      v-if="isShowMore" 
      v-expose="{
        id: '1-11-1-58',
        data: viewMoreAnalysis
      }"
      v-tap="{
        id: '1-11-1-101',
        data: viewMoreAnalysis
      }"
      class="shipping-list-more" 
      @click="clickMore" 
    >
      {{ language.SHEIN_KEY_PWA_21044 }}
      <i
        class="sui_icon_more_down_12px_2"
        :class="[iconPrefix]"
      ></i>
    </div>
  </ul>
</template>

<script>
import ShippingListItem from '@/public/src/pages/checkout/vue_tpls/shipping_method/ShippingMethodItem.vue'
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-11-1' })


export default {
  name: 'ShippingMethodList',
  components: {
    ShippingListItem
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    shippingMethods: {
      type: Array,
      default: () => []
    },
    cartsData: {
      type: Array,
      default: () => []
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    primeCouponCount: {
      type: Number,
      default: 0
    },
    defaultShippingItem: {
      type: Object,
      default: () => {}
    },
    addItemContentInfo: {
      type: Object,
      default: () => {}
    },
    isAllQuickShip: {
      type: Boolean,
      default: false
    },
    isQuickShipTitle: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isClickMore: false,
      defaultSelectedIndex: -1,
    }
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'defaultShipping', 
      'changeShopType',
    ]),
    ...mapGetters('checkout', [
      'iconPrefix', 
      'orderInterceptByAbt',
    ]),
    viewMoreAnalysis() {
      return { 
        is_default: '-',
        shipping_method: 'view_more',
        shipping_time: '-',
        shipping_fee: '-',
        mall_code: this.mallCode, 
        shipping_time_desc: '-',
        scenes: '-',
        is_folded: 0,
        sequence: 2,
        style: 'list',
        default_type: '-'
      }
    },
    checked() {
      return this.defaultShippingItem?.index || 0
    },
    isShowMore() {
      if (!this.isShowMoreSwitch) return false
      const isShowMore = !this.changeShopType && !this.isClickMore
      
      return isShowMore
    },
    // 是否需要展示more强制开关
    isShowMoreSwitch() {
      const { show, position } = this.positionConfig
      if (!show || +position == 0 || this.shippingMethods.length <= +position ) return false
      return true
    },
    positionConfig() {
      let show = false
      let position = 0

      for (let i = 0; i < this.shippingMethods?.length; i++) {
        // 若后端返回的is_fold=1，在现有运输方式排列顺序的基础上将该运输方式隐藏
        // 若后台配置了BC运输方式折叠，此时展示A方式，BCD方式被隐藏
        if (this.shippingMethods[i]?.is_fold === 1) {
          show = true
          position = i
          break
        }
      }

      return { show, position }
    }
  },
  watch: {
    checked: {
      handler(v) {
        const { position } = this.positionConfig
        const currShippingMethod = this.shippingMethods[v] || {}
        let userChecked = -1
        if(currShippingMethod.is_available == 1 || !this.orderInterceptByAbt) userChecked = v

        if (userChecked != -1 && +v >= +position) this.isClickMore = true
      },
      immediate: true
    },
  },
  mounted() {
    const filterDefauShip = this.defaultShipping?.find(mall => mall.mall_code == this.mallCode)
    this.defaultSelectedIndex = filterDefauShip?.index || 0
  },
  methods: {
    showDayPercentList(type) {
      this.$emit('showDayPercent', type)
    },
    clickMore() {
      this.isClickMore = true
    },
    handleSelect({ item, addItemUpdate, isInterceptBack, skipShipIntercept }) {
      const index = this.shippingMethods.findIndex(_ => _.transport_type == item.transport_type)
      // 点击more按钮后，点击展开后面的运输方式上报 
      if (this.isShowMoreSwitch && index >= +this.positionConfig.position) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-65',
        })
      }

      this.$emit('selected', { item, addItemUpdate, isInterceptBack, skipShipIntercept })
    },
  },
}
</script>

<style lang="less">
.shipping-list-more {
  text-align: center;
  color: @sui_color_gray_dark3;
  padding: 20/75rem 0;
  padding-bottom: 0;
}

.mche-edit-ship {
  padding-bottom: 20/75rem;
  .padding-r(24/75rem);
  >.last-shipping-item {
    padding-bottom: 0;
  }
}
</style>
