<template>
  <div
    class="coupon-guide-use__wrap"
    @click="handleClick"
  >
    <div class="tip-left">
      {{ couponTips }}
    </div>
    <div class="tip-right">
      <i class="icon suiiconfont sui_icon_viewmore_14px_2"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { template as _commonTemplate } from '@shein/common-function'

export default {
  name: 'CouponGuideUse',
  computed: {
    ...mapState('checkout', ['bestCoupon', 'language']),
    couponTips() {
      return this.template(this.bestCoupon?.discount_price?.amountWithSymbol, this.language.SHEIN_KEY_PWA_25056)
    },
  },
  methods: {
    template: _commonTemplate,
    handleClick() {
      this.$emit('click-coupon-guide')
    },
  }
}
</script>

<style lang="less" scoped>
.coupon-guide-use__wrap {
  position: absolute;
  top: -20/75rem;
  transform: translateY(-100%);
  background-color: rgba(0,0,0,.8);
  width: 702/75rem;
  height: auto;
  padding: 24/75rem;
  border-radius: 8/75rem;
  .flexbox();
  align-items: center;
  justify-content: space-between;
  color: @sui_color_white;

  .tip-right {
    .padding-l(12/75rem);
  }
}
</style>
