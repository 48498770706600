<template>
  <div
    v-if="storeData && storeData.status == 1"
    class="mcheo-addr default mshe-flexbetween mshe-wrapper store-address"
    role="button"
    @click="emitStoreAddress('edit')"
  >
    <div class="item address-content">
      <div class="name-phone">
        <div class="name">
          {{ storeData.lname }}
          {{ storeData.fname }}
          {{ storeData.value }}
        </div>
        <div class="phone">
          {{ storeData.tel }}
        </div>
      </div>
      <p v-if="storeData.countryId == 209">
        <s-label
          type="neutral"
        >
          {{ language.SHEIN_KEY_PWA_16611 }}
        </s-label>
        {{ storeData.storeName }}
        {{ storeData.storeType == 1 ? '(7-ELEVEN)' : '(全家便利商店)' }}
      </p>
      <p v-else>
        <s-label
          type="neutral"
        >
          {{ language.SHEIN_KEY_PWA_16611 }}
        </s-label>
        {{ storeData.storeName }}
        {{ storeData.address1 }}
        {{ storeData.address2 }}
      </p>
      <p>
        <template v-if="storeData.countryId == 209">
          {{ storeData.address1 }}
          {{ storeData.address2 }}
          {{ storeData.street }}
          {{ storeData.district }}
        </template>
        <template v-else>
          {{ storeData.district }}
          {{ storeData.street }}
        </template>
        {{ storeData.city }}
        {{ storeData.state }}
        {{ storeData.countryName }} {{ storeData.countryId != 209 ? storeData.postcode : '' }}
      </p>
      <div
        v-if="sensitiveNote && !smallAddress"
        class="mcheo-addr__tips"
      >
        {{ sensitiveNote }}
      </div>
    </div>
    <i
      v-if="!smallAddress"
      class="sui_icon_more_right_16px"
      :class="[fsIconPrefix]"
    ></i>
  </div>
  <div
    v-else
    class="mcheo-addr default mshe-flexbetween mshe-wrapper store-address store-address-new"
  >
    <div
      class="checkpay-con checkpay-store"
      @click="emitStoreAddress('add')"
    >
      +{{ language.SHEIN_KEY_PWA_16616 }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AddressStore',
  props: {
    smallAddress: { // 小地址卡片隐藏部分信息
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('checkout', ['language', 'checkout', 'storeData']),
    ...mapGetters('checkout', ['fsIconPrefix']),
    sensitiveNote () {
      const sensitiveRuleVoList = this.storeData?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    }
  },
  methods: {
    emitStoreAddress(type) {
      this.$emit('emitStoreAddress', type)
    },
  }
}
</script>

<style lang="less" scoped>
.item{
  padding: 0;
  .text-overflow();
  .name-phone{
    display: flex;
    line-height: 38/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/75rem;
    .name{
      .margin-r(16/@w2-375rem);
      font-weight: 600;
      .text-overflow();
    }
    .phone{
      font-size: 24/@w2-375rem;
      color: #767676;
      white-space: nowrap;
      overflow: unset;
      text-overflow: unset;
    }
  }
  p{
    line-height: 34/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/@w2-375rem;
    color: #222;
    .text-overflow();
    .padding-r(24/@w2-375rem);
    & when (@IS_RW) {
      color: #333;
    }
  }
}

.address-store-wrap {
  .address-content {
    p {
      .padding-r(12/75rem);
    }
  }
}
.mcheo-addr {
  &__tips {
    padding-top: .106rem;
    color: @sui_color_unusual;
    font-size: 12px;
    white-space: normal;
    word-break: break-all;
  }
}

.store-address-new {
  padding: 66/75rem 0;
  font-weight: 700;
}
</style>
