<template>
  <div
    ref="checkoutSummaryFooterRef"
    class="j-checkout-footer checkout-summary-footer"
  >
    <ClientOnly>
      <div>
        <!-- 关闭自动用券，引导用户用券提示 -->
        <CouponGuideUse 
          v-if="showCouponUseGuideTips && !isResultAutoUsePoints"
          v-expose="{ id: '1-11-1-151' }"
          v-tap="{ id: '1-11-1-152' }"
          class="checkout-coupon-guide"
          @click-coupon-guide="handleScrollCoupon"
        />
        <!-- 自动用券成功提示 -->
        <CouponAutoTips 
          v-if="showCouponAutoTips || isResultAutoUsePoints"
          v-expose="{ id: '1-11-1-146' }"
          v-tap="{ id: '1-11-1-147' }"
          class="checkout-auto-coupon"
          :show-coupon-auto-tips="showCouponAutoTips"
          :is-auto-use-points="isResultAutoUsePoints"
          :auto-use-resources-price="autoUseResourcesPrice"
          @click-auto-tips="handleScrollCoupon"
        />
        <!-- 违禁品提示，暂时只是商品中含有烟草类的商品 -->
        <ContrabandTips v-if="isShowContrabandTips" />
        <!-- 用券提示优先级高于支付引导弹窗 -->
        <!-- 引导悬浮窗 -->
        <PayGuideModule
          v-if="visiblePayGuide && (!isResultAutoUsePoints && !showCouponAutoTips && !showCouponUseGuideTips)"
          class="checkout-pay-guide"
          :guide-logo-list="checkoutGuideLogoList"
          :title="language.SHEIN_KEY_PWA_23556"
          @on-click="handleScrollToPayment"
        />

        <!-- 底部内容 - 选择支付方式 -->
        <footer-select-payment 
          v-if="isFooterSelectPayment"
          ref="footerSelectPaymentRef"
          :checkout="checkout"
          :banks-config="banksConfig"
          :language="language"
          :status="status"
          :price="price"
          :locals="locals"
          :icon-prefix="iconPrefix"
          :paymentMethodsProps="paymentMethodsProps"
          :guide-logo-list="checkoutGuideLogoList"
          @payment-options-oper="handlePaymentOptionsOper"
          @create-order="handlerDrawerPayConfirm"
        >
          <SummaryCheckoutBtn 
            :paypal-btn-show="paypalBtnShow"
            :paymentMethodsProps="paymentMethodsProps"
            :paypal-venmo-btn-show="paypalVenmoBtnShow"
            :gov-tax-price="govTaxPrice"
            :is-drawer-btn="true"
            :isShowFreePriceTip="false"
            class="footer-summary-btn"
          />
        </footer-select-payment>

        <!-- 营销裂变 -->
        <s-alert
          v-if="extraPromotionTip && !isShowContrabandTips && !isFooterSelectPayment"
          type="warning"
        >
          <div v-html="extraPromotionTip"></div>
        </s-alert>
        <!-- cod支付后端强制转换货币提示 -->
        <div
          v-if="codCurrencyTip"
          class="cod-currency-tip"
        >
          <s-popover
            placemen="top-end"
            trigger-type="user"
            :value="!!codCurrencyTip"
            :append-to-body="false"
            :show-close-icon="true"
            theme="light"
            :prop-style="{'width': '9.36rem', 'left': '0', 'right': '0'}"
          >
            <em class="tips">{{ codCurrencyTip }}</em>
            <div slot="reference"></div>
          </s-popover>
        </div>
      </div>
    </ClientOnly>

    <!-- 价格订单明细抽屉 -->
    <transition name="detail">
      <div
        v-if="isShowPriceDetail"
        class="price-detail-drawer j-summary-mask"
      >
        <s-sticky>
          <div class="price-detail-drawer__header">
            <span class="price-detail-drawer__header-text">{{ language.SHEIN_KEY_PWA_24380 }}</span>
            <i 
              class="suiiconfont sui_icon_close_16px_2 price-detail-drawer__header-close" 
              @click.stop="hangeleChangePriceDetail"
            ></i>
          </div>
        </s-sticky>

        <price-info 
          v-if="isClient" 
          :pre-pay-un-pay-params="prePayUnPayParams" 
        />
      </div>
    </transition>
<!-- 
    <s-drawer
      :visible.sync="visiblePriceDetail"
      direction="btt"
      :append-to-body="true"
      animate-type="small"
    >
      <template slot="top">
        {{ language.SHEIN_KEY_PWA_24380 }}
      </template>
      <price-info v-if="isClient" />
    </s-drawer> -->

    <!-- 底部内容（左侧内容、右侧按钮） -->
    <FooterSummaryEffiency
      class="j-summary-effiency"
      :check-show-total-fee="checkShowTotalFee"
      :gov-tax-price="govTaxPrice"
      :is-show-ca-g-s-t="isShowCaGST"
      :pre-pay-un-pay-params="prePayUnPayParams"
      @totalFeeMaskTips="totalFeeMaskTips"
      @clickMaskGst="clickMaskGst"
      @toggleTotal="toggleTotal"
    >
      <s-popover
        class="checkout-activity-popover__wrap not-fsp-element"
        :class="[isShowNewFooterLayoutStyle ? 'checkout-activity-popover__wrap-expansion' : '', {
          'checkout-activity-popover__wrap-ar': GB_cssRight
        }]"
        placemen="top-end"
        :append-to-body="false"
        :show-close-icon="true"
        :value="showActivityPopover"
        trigger-type="user"
        :prop-style="{ minWidth: '132px', maxWidth: '260px', background: `rgba(0, 0, 0, 0.8)` }"
        :outside-close="false"
        @opened="openActivityPopover"
        @closed="closeActivityPopover"
      >
        <ActivityPopover 
          class="not-fsp-element"
          :checkout="checkout"
          :language="language"
          :activityPopoverType="activityPopoverType"
          :integrityPromotionInfo="integrityPromotionInfo"
          :promotionList="promotionList"
          :underPriceAbtValue="underPriceAbtValue"
          :flashSaleGroupList="flashSaleGroupList"
          :earliestExpiryCoupon="earliestExpiryCoupon"
        />
        <SummaryCheckoutBtn 
          slot="reference"
          ref="summaryCheckoutBtnRef"
          :paypal-btn-show="paypalBtnShow"
          :paypal-venmo-btn-show="paypalVenmoBtnShow"
          :paymentMethodsProps="paymentMethodsProps"
          :gov-tax-price="govTaxPrice"
          @createOrder="createOrder"
        />
      </s-popover>
    </FooterSummaryEffiency>

    <!-- 蒙层 -->
    <transition name="mask">
      <div
        v-if="isShowPriceDetail"
        class="price-detail-mask"
        @click="hangeleChangePriceDetail"
      ></div>
    </transition>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { scrollFix } from 'public/src/pages/common/utils/index.js'
import { Drawer as SDrawer } from '@shein/sui-mobile'

import { transformExtraPromotionTip } from '../utils'
import { filterPromitionGoods } from 'public/src/pages/checkout/config/tools.js'

// components
import FooterSummaryEffiency from './footer_summary_effiency.vue'
import SummaryCheckoutBtn from './summary_checkout_btn.vue'
import CouponAutoTips from '../vue_tpls/coupon_auto_tips.vue'
import ContrabandTips from '../vue_tpls/ContrabandTips.vue'
import CouponGuideUse from '../vue_tpls/coupon_guide_use.vue'
import ActivityPopover from 'public/src/pages/checkout/vue_tpls/activity_popover/Index.vue'
import { Popover as SPopover } from '@shein/sui-mobile'
import FooterSelectPayment from './footer_payment/footer_select_payment.vue'

const PAY_GUIDE_STORAGE_KEY = 'PAY_GUIDE_STORAGE_KEY_CHECKOUT'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const { GB_cssRight } = gbCommonInfo

export default {
  name: 'CheckoutFooter',
  components: {
    ClientOnly,
    SPopover,
    PayGuideModule: () => import('../vue_tpls/PayGuideModule.vue'),
    FooterSummaryEffiency,
    SummaryCheckoutBtn,
    priceInfo: () => import('../vue_tpls/summary/PriceInfo.vue'),
    CouponAutoTips,
    ContrabandTips,
    CouponGuideUse,
    ActivityPopover,
    FooterSelectPayment,
    SDrawer
  },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: false 
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => {}
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    },
    isFooterSelectPayment: {
      type: Boolean,
      default: false
    },
    prePayUnPayParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visiblePayGuide: false,
      windowScrollY: 0,
      isAlreadyGuide: true,
      scroll_fix: null,
      isResultAutoUsePoints: false,
      isCloseActivityPopover: false,
      activityPopoverCache: [],
      alreadyExposeScene: false,
      isClient: false,
      GB_cssRight,
      summaryCheckoutBtnRef: null
    }
  },
  computed: {
    ...mapState('checkout', [
      'price', 
      'language', 
      'codCurrencyTip', 
      'checkout', 
      'checkoutAbtInfo',
      'checkoutGuideLogoList', 
      'locals',
      'autoCouponInfo',
      'bestCouponInfo',
      'canInitPayGuide',
      'showShippingFeeDrawer',
      'asyncComponentLoadedStatus',
      'integrityPromotionInfo',
      'isInitPromotion',
      'promotionList',
      'iconPrefix',
      'isShowPriceDetail',
      'buyNowNo',
    ]),
    ...mapGetters('checkout', [
      'isShowSupportInfo', 
      'showSaveAmount', 
      'isShowTotalFee', 
      'showOrderRulesByAbt',
      'showOrderBubbleSort',
      'underPriceAbtValue',
      'flashSaleGroupList',
      'earliestExpiryCoupon',
      'isShowNewFooterLayoutStyle',
    ]),
    SiteUID(){
      return this.locals?.SiteUID || gbCommonInfo.SiteUID
    },
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    checkShowTotalFee () {
      return this.isShowTotalFee && 
      this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_rate > 0 &&
      Number(this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amount || '0') > 0
    },
    govTaxPrice () {
      return this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amountWithSymbol || 0
    },
    // 判断是否展示ca gst 提示文案
    isShowCaGST () {
      const { countryId } = this.checkout && this.checkout.default_address || {}
      return (countryId == '38' || this.SiteUID == 'mca')
    },
    extraPromotionTip () {
      const { language } = this
      return transformExtraPromotionTip({ language, extraPromotion: this.price.extraPromotion })
    },
    showCouponAutoTips() {
      return this.autoCouponInfo.show
    },
    showCouponUseGuideTips() {
      return this.bestCouponInfo.show 
    },
    // 是否展示购买违禁品的提示，比如：烟草，计价的接口返回标识是1
    isShowContrabandTips() {
      return (this.checkout.mall_caculate_info?.cart_sub_infos || []).findIndex(item => +item.tobacco_goods === 1) > -1
    },
    isAutoUsePoint() {
      return this.checkout?.abtInfo?.['orderautopoints']?.param?.isAutoPoint != 0 && this.checkout?.mall_caculate_info?.pointPrice?.amount > 0
    },
    isAutoUseWallet() {
      return this.checkout?.results?.autoWalletBalanceAbt?.param?.isAuto != 0 && this.checkout?.mall_caculate_info?.walletPrice?.amount > 0
    },
    // 自动使用了积分/钱包
    isAutoUsePointsOrWallet() {
      return this.isAutoUseWallet || this.isAutoUsePoint
    },
    autoUseResourcesPrice() {
      return this.checkout?.mall_caculate_info?.auto_use_resources_price?.amountWithSymbol
    },
    activityPopoverInfo() {
      const { promotion_discount_info = [], carts = [] } = this.checkout.results?.carts?.cartInfo || {}

      return {
        promotion: !!(promotion_discount_info.find(promotion => +promotion.discount_price?.amount > 0)),
        inventory: !!(carts.some(product => Number(product?.stock_show_type) === 0)), // stock_show_type等于0表示低库存
        coupon: !!this.earliestExpiryCoupon
      }
    },
    activityPopoverType() {
      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      // 当同时满足多个利益点，则按 abt 优先级进行展示
      const type = this.showOrderBubbleSort.find(value => (
        popoverList.includes(value)
      ))
      // if(popoverList.includes('promotion')) {
      //   type = 'promotion'
      // } else if(popoverList.includes('lowStock')) {
      //   type = 'lowStock'
      // } else if(popoverList.includes('coupon')) {
      //   type = 'coupon'
      // }

      return type
    },
    activityPopoverList() {
      if(!this.isClient || !this.showOrderRulesByAbt) return []

      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      return popoverList
    },
    isVisibleGuide() {
      return this.showCouponUseGuideTips || this.showCouponAutoTips || this.isResultAutoUsePoints || this.visiblePayGuide
    },
    // 判断使用原始的按钮还是气泡组件的按钮
    showActivityPopover() {
      if (!this.isClient) {
        return false
      }
      // isInitPromotion(促销信息异步请求，气泡展示抖动)
      return !!this.activityPopoverList.length && 
        !this.isCloseActivityPopover && 
        !this.isVisibleGuide && 
        this.isInitPromotion &&
        !!this.activityPopoverType
    },
    
    isShowFooterSelectPaymentByAbt() {
      if (this.paymentMethodsProps?.nowPageType !== 1) return false
      const { orderpaylistbottomdefault = {}, orderpaylistbottomnodefault = {} } = this.checkout?.abtInfo || {}
      return this.paymentMethodsProps?.nowPageType === 1 && (
        orderpaylistbottomdefault?.param?.orderpaylistbottomstatus == 1 || 
        orderpaylistbottomnodefault?.param?.orderpaylistbottomnodefaultstatus === 'bottom'
      )
    },
    visiblePriceDetail: {
      get() {
        return this.isShowPriceDetail
      },
      set(v) {
        this.assignState({ isShowPriceDetail: v })
      }
    }
  },
  watch: {
    isShowPriceDetail: {
      handler(v) {
        if(typeof window == 'undefined') return
        if(v) {
          $('.j-uptt').css('visibility', 'hidden')
        } else {
          $('.j-uptt').css('visibility', 'visible')
          $('.j-uptt').css('z-index', 10)
        }
        this.scroll_fix?.(v)
      },
      immediate: true
    },
    showShippingFeeDrawer: {
      handler(v) {
        if(v) {
          this.assignState({ isShowPriceDetail: false })
          this.scroll_fix?.(false)
        }
      },
      immediate: true
    },
    showCouponAutoTips: {
      handler(v) {
        if(v) this.initCouponTipsStatus()
      },
      immediate: true
    },
    showCouponUseGuideTips: {
      handler(v) {
        if(v) this.initCouponGuideUse()
      },
      immediate: true
    },
    canInitPayGuide: {
      handler(v) {
        if(v) this.initPayGuideStatus()
      },
      immediate: true
    },
    activityPopoverList: {
      handler(v) {
        if(!!v?.length) {
          this.getActivityPopoverCache()
          this.handleCloseActivityPopover()
        }
      },
      deep: true
    },
    activityPopoverInfo: {
      handler() {
        this.exposeScene()
      },
      deep: true,
      immediate: true
    },
  },
  mounted () {
    this.isClient = true
    this.scroll_fix = scrollFix()

    this.isAlreadyGuide = !!window.localStorage?.getItem?.(PAY_GUIDE_STORAGE_KEY)
    // 下单页支付方式楼层置底展示, 则线上的辅助决策信息将全部干掉
    if (!this.isAlreadyGuide && window.localStorage?.setItem && !this.isShowFooterSelectPaymentByAbt) {
      window.localStorage.setItem(PAY_GUIDE_STORAGE_KEY, 1)
    }

    this.$nextTick(() => {
      const footerH = $('.j-summary-effiency')?.height() || 0
      const windowHeight = window.innerHeight || 0
      const maxHeight = windowHeight * 0.8
      $('.j-summary-mask').css('bottom', `${footerH}px`)
      $('.j-summary-mask').css('max-height', `${maxHeight - footerH}px`)
    })

    // 需要设置底部区域宽度，因为是固定定位，不设置宽度会出现底部区域超出主体内容的范围
    const containerWidth = document.body.clientWidth || document.documentElement.clientWidth
    this.$refs.checkoutSummaryFooterRef.style.width = `${containerWidth}px` || '100%'

    if (this.isAutoUsePointsOrWallet) {
      this.isResultAutoUsePoints = true

      window.addEventListener('scroll', this.pageScrollListener)
    }

    document.addEventListener('click', () => {
      this.isResultAutoUsePoints = false
    })

    this.getActivityPopoverCache()
    if(!!this.activityPopoverCache.length) this.isCloseActivityPopover = true

    appEventCenter.$on('applepay-create-order', () => {
      this.createOrder()
    })

    window?.appEventCenter?.$on('capitecBankSaved', () => {
      this.createOrder()
    })
  },
  updated () {
    this.$nextTick(() => {
      const footerH = $('.j-summary-effiency')?.height() || 0
      const windowHeight = window.innerHeight || 0
      const maxHeight = windowHeight * 0.8
      $('.j-summary-mask').css('bottom', `${footerH}px`)
      $('.j-summary-mask').css('max-height', `${maxHeight - footerH}px`)
    })
  },
  methods: {
    ...mapMutations('checkout', ['showMaskTips', 'changeParamsStatus', 'assignState']),
    hangeleChangePriceDetail() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    exposeScene() {
      if(typeof window == 'undefined') return
      if(this.alreadyExposeScene) return

      if(!!this.activityPopoverType) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-172',
          extraData: {
            scenes: 'placeorderBenefits',
            benefits_type: this.activityPopoverType == 'promotion' ? 'huodong' : (this.activityPopoverType == 'inventory' ? 'kucun' : 'quan'),
          }
        })
        this.alreadyExposeScene = true
      }
    },
    setActivityPopoverCache(value) {
      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!value && !popoverList.includes(value)) {
        popoverList.push(value)
        sessionStorage.setItem('checkout_activity_popover', JSON.stringify(popoverList))
        this.activityPopoverCache = popoverList
      }
    },
    getActivityPopoverCache() {
      if(typeof window == 'undefined') return

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      this.activityPopoverCache = popoverList
    },
    handleCloseActivityPopover() {
      if(!!this.activityPopoverCache.length && !this.activityPopoverCache.includes(this.activityPopoverType)) this.isCloseActivityPopover = true
    },
    openActivityPopover() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-188',
        extraData: {
          benefits_type: this.activityPopoverType == 'promotion' ? 'huodong' : (this.activityPopoverType == 'inventory' ? 'kucun' : 'quan'),
        }
      })

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!this.activityPopoverType && !popoverList.includes(this.activityPopoverType)) {
        this.setActivityPopoverCache(this.activityPopoverType)
      }

      const { CHECKOUT_ACTIVITY_POPOVER_TIME = 8 } = this.checkout || {}
      setTimeout(() => {
        this.isCloseActivityPopover = true
      }, +CHECKOUT_ACTIVITY_POPOVER_TIME * 1000)
    },
    closeActivityPopover() {
      this.isCloseActivityPopover = true
      daEventCenter.triggerNotice({
        daId: '1-11-1-189',
      })
    },
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      this.assignState({ isShowPriceDetail: false })
      this.$emit('create-order')
      this.scroll_fix(false)
    },
    clickMaskGst() {
      this.showMaskTips(this.language.SHEIN_KEY_PWA_20400)
    },
    totalFeeMaskTips () {
      this.showMaskTips(this.language.SHEIN_KEY_PWA_18954)
    },
    pageScrollListener () {
      this.windowScrollY = window.scrollY
      window.requestAnimationFrame(() => {
        if (this.windowScrollY > 15) {
          this.isAlreadyGuide = 1
          this.visiblePayGuide = false
          
          this.changeParamsStatus({
            type: 'autoCouponInfo',
            params: {
              show: false,
            }
          })
          this.isResultAutoUsePoints = false
          this.changeParamsStatus({
            type: 'bestCouponInfo',
            params: {
              show: false
            }
          })
          window.removeEventListener('scroll', this.pageScrollListener)
        }
      })
    },
    // 滚动到支付方式列表
    handleScrollToPayment () {
      const topH = document.querySelector('.payment-options-container').offsetTop
      const headH = document.querySelector('#checkoutHeader').clientHeight
      window.scrollTo({
        top: topH - headH,
        behavior: 'smooth',
      })
    },
    initPayGuideStatus () {
      if(typeof window == 'undefined') return

      const { checkout = {}, price = {}, isShowSupportInfo } = this
      if (!Object.keys(checkout).length || !Object.keys(price).length || !isShowSupportInfo) return
      // 阿波罗配置 - 是否需要隐藏引导悬浮窗
      if (gbCommonInfo?.HIDDEN_PAY_GUIDE_TIPS) return
      this.$nextTick(() => {
        // 判断支付方式列表是否在窗口中露出，未露出才需要提示
        // 支付方式列表dom距离顶部距离
        const paymentMethodsContainerTop = document.querySelector('.mcheo-payment__methods')?.offsetTop || 0
        // 底部按钮距离顶部距离
        const summaryFooterTop = document.querySelector('.checkout-summary-footer')?.offsetTop || 0
        if (paymentMethodsContainerTop > summaryFooterTop) {
          if (!this.isAlreadyGuide && !this.visiblePayGuide) {
            this.visiblePayGuide = true
            this.windowScrollY = 0
            window.addEventListener('scroll', this.pageScrollListener)
          }
        }
      })
    },
    toggleTotal() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    initCouponGuideUse() {
      if(this.bestCouponInfo.alreadyShowed) return

      this.changeParamsStatus({
        type: 'bestCouponInfo',
        params: {
          alreadyShowed: true
        }
      })
      if(typeof window == 'undefined') return
      window.addEventListener('scroll', this.pageScrollListener)
    },
    initCouponTipsStatus() {
      if(this.autoCouponInfo.alreadyShowed) return

      this.changeParamsStatus({
        type: 'autoCouponInfo',
        params: {
          alreadyShowed: true
        }
      })

      if(typeof window == 'undefined') return
      window.addEventListener('scroll', this.pageScrollListener)
    },
    // 滚动到券列表入口
    handleScrollCoupon() {
      const topH = document.querySelector('.checkout-coupon__entry').offsetTop
      const headH = document.querySelector('#checkoutHeader').clientHeight
      const noticeH = document.querySelector('.checkout-notice')?.clientHeight || 0
      window.scrollTo({
        top: topH - (headH + noticeH),
        behavior: 'smooth',
      })
    },

    handlePaymentOptionsOper(data) {
      this.$emit('payment-options-oper', data)
    },
    handlerDrawerPayConfirm () {
      this.$refs?.summaryCheckoutBtnRef?.handlerClick()
    }
  }
}
</script>

<style lang="less" scoped>
.checkout-coupon-guide {
}
.checkout-auto-coupon {
  margin: 0 0.32rem;
}
.checkout-pay-guide {
  margin: 0 0.32rem;
}

.checkout-summary-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	border-top: 1px solid #e5e5e5;
	box-shadow: 0 -0.1rem 0.1rem rgba(0,0,0,.06);
	z-index: @zindex-attrmenusize;

	.cod-currency-tip {
		width: 100%;
		position: absolute;
		top: 0;
		.left(0);
    transform: translateY(-100%);
		padding: 0 0.32rem;
		margin-top: -0.13333rem;
	}
}
// 订单明细抽屉内容样式
.price-detail-drawer {
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid @sui_color_gray_weak1;
  overflow-y: scroll;
  z-index: @zindex-hack;
  &__header {
    position: relative;
    width: 100%;
    padding: 24/75rem 48/75rem;
    border-bottom: 1px solid @sui_color_gray_weak1;
    text-align: center;
    background: #fff;
    z-index: @zindex-out;
  }
  &__header-text {
    color: @sui_color_gray_dark3;
    .font-dpr(28px);
  }
  &__header-close {
    position: absolute;
    top: 4/75rem;
    right: 4/75rem;
    color: @sui_color_gray_light1;
  }
}
// 订单明细抽屉的动画
.detail-enter-active {
  transition: transform 0.3s;
}
.detail-leave-active {
  transition: transform 0.25s;
} 
.detail-enter, .detail-leave-to {
  transform: translateY(100%);
}
.detail-leave-from {
  transform: translateY(0%);
}

// 订单明细抽屉的遮罩
.price-detail-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.mask-enter, .mask-enter-active {
  animation: mask-fade-in 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.mask-leave-leave, .mask-leave-active {
  animation: mask-fade-out 0.25s cubic-bezier(0, 0, 0.2, 1) forwards;
}

@keyframes mask-fade-in {
  0% {
    background: rgba(0,0,0,0);
  }
}

@keyframes mask-fade-out {
  100% {
    background: rgba(0,0,0,0);
  }
}
</style>

<style lang="less">
.checkout-activity-popover__wrap {
  .S-popover__main {
    /* stylelint-disable-next-line declaration-no-important */
    top: -156/75rem !important; // 没有使用bottom（110/75）的原因是：当左侧税费换行的时候，会出现右侧按钮上移，然后覆盖气泡
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    right: 0 !important;
  }
  .S-popover__main-content {
    background: unset;
    padding-top: 24/75rem;
    padding-bottom: 24/75rem;
  }
  .S-popover__main-dark::before {
    background: unset;
    border: 5px solid rgba(0, 0, 0, .8);
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
.checkout-activity-popover__wrap-ar {
  .S-popover__main-dark::before {
    background: unset;
    border: 5px solid rgba(0, 0, 0, .8);
    border-right-color: transparent;
    border-top-color: transparent;
  }
}
.checkout-activity-popover__wrap-expansion {
  flex: 1;
  min-width: 0;
}
</style>
