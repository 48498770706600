const iconConfig = {
  'prime': {
    name: 'sui_icon_club_logo_fill_14px',
    color: '#C96E3F',
    size: '14px'
  },
  'flash': {
    name: 'sui_icon_flashsale_15px',
    color: '#FACF19',
    size: '14px'
  },
  'stock': {
    name: 'sui_icon_hot_fill_15px',
    color: '#FA6237',
    size: '14px'
  }
}

export {
  iconConfig,
}
