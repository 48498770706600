<template>
  <div class="low-stock-popover">
    <GoodsList 
      v-if="lowStockCartsData.length"
      class="j-activity-left"
      :language="language"
      :goodsList="lowStockCartsData"
    />
    <Tips 
      v-if="tipsDescription && tipsDescription.length"
      :language="language"
      :icon="tipsIcon"
      :description="tipsDescription"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'
import { iconConfig } from './config'

export default {
  name: 'LowStockPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    // 低库存的数据
    lowStockCartsData() {
      const cartsInfo = this.checkout?.results?.carts?.carts || []
      return cartsInfo.filter(product => Number(product?.stock_show_type) === 0)
    },
    tipsDescription() {
      if (this.lowStockCartsData.length === 1) {
        const stockNum = this.lowStockCartsData[0]?.product?.stock || 0
        return `<em>${this.template(stockNum, this.language.SHEIN_KEY_PWA_29166)}</em> ${this.language.SHEIN_KEY_PWA_29167}`
      }
      return `${this.language.SHEIN_KEY_PWA_29168} <em>${this.language.SHEIN_KEY_PWA_29169}</em>`
    },
    tipsIcon() {
      return iconConfig?.stock || {}
    },
  },
  methods: {
    template,
  }
}
</script>
