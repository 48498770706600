<template>
  <div
    :class="[
      isShowFreeShippingTip ? 'checkout-btn-wrapper' : '',
      isShowNewFooterLayoutStyle ? 'checkout-new-layout' : 'summary-checkout-btn',
    ]"
  >
    <div
      ref="checkoutActualBtnRef"
      :style="{ '--actual-btn-width': actualBtnWidth }"
      class="checkout-btn_container"
    >
      <div
        v-if="animationStripControl"
        :class="[GBCssRight ? 'checkout-btn-strip_ar' : 'checkout-btn-strip']"
      ></div>
      <!-- paypal ga 按钮 -这里使用v-show，避免使用v-if而导致sdk找不到dom -->
      <div
        v-show="paypalBtnShow"
        :id="paypalBtnID"
        style="width: 100%"
      ></div>
      <!-- applepay -->
      <ApplePayBtn
        v-if="applePayBtnShow"
        ref="appleBtnRef"
        :propsStyle="{ width: '100%', height: '1.06rem' }"
        @handleBtnClick="createOrderByApplePay"
      />
      
      <!-- venmo按钮 -->
      <s-button
        v-if="paypalVenmoBtnShow && !applePayBtnShow"
        ref="venmoBtnRef"
        :type="['primary', 'H80PX']"
        :loading="createOrderLoading"
        width="100%"
        class="venmo-btn j-checkout-btn"
        @click="createOrder"
      >
        <img
          v-show="!createOrderLoading"
          :src="IMG_LINK['blue-venmo-button']"
          class="venmo-btn-img"
        />
      </s-button>
      <!-- 普通按钮 -->
      <s-button
        v-if="!paypalBtnShow && !paypalVenmoBtnShow && !applePayBtnShow"
        ref="btnRef"
        class="j-checkout-btn"
        width="100%"
        :style="{ border: 'none', backgroundColor: normalBtnData.brandColor }"
        :type="['primary', 'H80PX']"
        :loading="createOrderLoading"
        @click="createOrder"
      >
        <!-- 上文下图布局 -->
        <div
          v-if="nomalBtnLayoutType == 1"
          class="btn-first-layout"
        >
          <p
            class="btn-first-layout__text"
            v-html="normalBtnData.placeOrderText"
          >
          </p>
          <div
            v-if="isShowBNPLExclusiveStyle && normalBtnData.logo"
            class="btn-first-layout__logo"
          >
            <img
              :src="normalBtnData.logo"
              class="btn-first-layout__logo-img"
            />
          </div>
          <p
            v-else
            class="btn-first-layout__sub-text"
            v-html="normalBtnData.subText"
          ></p>
        </div>
        <!-- 左图右文布局 -->
        <div
          v-else-if="nomalBtnLayoutType == 2"
          class="btn-second-layout"
        >
          <img
            v-if="normalBtnData.logo"
            class="btn-second-layout__logo"
            :src="normalBtnData.logo"
          />
          <p
            class="btn-second-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
        </div>
        <!-- 左文右图布局 -->
        <div
          v-else-if="nomalBtnLayoutType == 3"
          class="btn-third-layout"
        >
          <p
            class="btn-third-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
          <img
            v-if="normalBtnData.logo"
            class="btn-third-layout__logo"
            :src="normalBtnData.logo"
          />
        </div>
        <!-- 默认 -->
        <div v-else>
          <SAdapterText
            v-if="isUseSAdapterText"
            min-size="10"
            :text="placeOrderTxt"
          />
          <template v-else>
            {{ placeOrderTxt }}
          </template>
        </div>
      </s-button>
    </div>
    <!-- 免邮提示 -->
    <div
      v-if="isShowFreeShippingTip"
      class="free-shipping-tip"
    >
      <ClientOnly>
        <labelsSwiper
          v-if="btnFreeQuickShipTips == 'quickshipFreeAll'"
          :config="labelSwiperConfig"
        />
      </ClientOnly>

      <template v-if="btnFreeQuickShipTips != 'quickshipFreeAll'">
        <Icon
          :name="quickShipFreeIcon"
          size="15px"
          color="#fff"
        />
        <span
        
          :class="['free-shipping-tip__text', isQuickShipBtnTips && 'italic-text']"
        >{{ btnFreeQuickShipTips }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { template, getQueryString } from '@shein/common-function'
import labelsSwiper from 'public/src/pages/checkout/page_tpls/components/atom/labelsSwiper.vue'
import ApplePayBtn from 'public/src/pages/components/ApplePayBtn.vue'

const { IMG_LINK } = gbCommonInfo
const normalBtnLayoutType = {
  TOP_TEXT_BOTTOM_IMAGE: 1, // 1是上文下图布局
  LEFT_IMAGE_RIGHT_TEXT: 2, // 2是左图右文布局
  LEFT_TEXT_RIGHT_IMAGE: 3, // 3是左文右图布局
  DEFAULT: -1 // 默认兜底
}

const applePayType = ['worldpay-applepay', 'nuvei-applepay', 'adyen-applepay', 'checkout-applepay', 'ingenico-applepay']

export default {
  name: 'SummaryCheckoutBtn',
  components: {
    SAdapterText,
    Icon,
    labelsSwiper,
    ApplePayBtn
  },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: false 
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: false
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    },
    govTaxPrice: {
      type: [Number, String],
      default: 0
    },
    isDrawerBtn: {
      type: Boolean,
      default: false
    },
    isShowFreePriceTip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      IMG_LINK,
      isClient: false,
      isUseSAdapterText: false,
      actualBtnWidth: 'auto', // 按钮的实际宽度
      isCheckoutBtnWidthChange: false,
      venmoBtnRef: null,
      btnRef: null,
      animationStripControl: false,
      fristAnimationStrip: 0,
      normalBtnData: {
        placeOrderText: '', // 按钮文案
        logo: '', // 支付方式的logo
        brandColor: '', // 品牌色（按钮用于设置背景色，用在BNPL的ABT实验为2的时候）
        subText: '', // 按钮副文案（用在BNPL的ABT实验为2的时候）
      },
      nomalBtnLayoutType: normalBtnLayoutType.DEFAULT, // 普通按钮的布局样式 1是上文下图布局、2是左图右文布局、3是左文右图布局
    }
  },
  computed: {
    ...mapGetters('checkout', ['isFreePrice', 'quickShipCheckoutStatus', 'isShowNewFooterLayoutStyle', 'isSelectTokenPay', 'isSelectInstallmentTokenPay', 'GBCssRight']),
    ...mapState('checkout', [
      'language',
      'createOrderLoading',
      'checkoutAbtInfo',
      'checkout',
      'status',
      'price',
      'isOrderTotalOut',
      'defaultSelectTokenPay',
      'installmentSelectBin',
      'defaultShipping',
      'addresslist',
      'locals',
      'isUseNewCard'
    ]),
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    getPlaceOrderButtonPayStatusByAbt() {
      return this.checkoutAbtInfo?.PlaceOrderButtonPay?.param?.PlaceOrderButtonPayStatus == 1
    },
    getBnplPlaceOrderStatsByAbt() {
      return Number(this.checkoutAbtInfo?.bnplPlaceOrder?.param?.bnplPlaceOrderStats)
    },
    quickShipFreeIcon(){
      return this.isQuickShipBtnTips ? 'sui_icon_qucikship_flat_24px' : 'sui_icon_free_shipping_12px'
    },
    labelSwiperConfig(){ 
      return [{
        text: this.quickShipCheckoutStatus.quickShipLabelText,
        icon: 'sui_icon_qucikship_flat_24px',
        quickship: true
      },
      {
        text: this.language.SHEIN_KEY_PWA_23851,
        icon: 'sui_icon_free_shipping_12px',
      }
      ]
    },
    totalPrice() {
      return this.price?.grandTotalPrice?.amountWithSymbol
    },
    // 是否展示免邮的提示标签
    isShowFreeShippingTip() {
      return (!this.IS_RW && (this.isFreePrice && this.checkoutAbtInfo?.placeOrderFreeShipping?.param?.placeOrderFreeShippingstatus === '1') || this.isQuickShipBtnTips) && this.isShowFreePriceTip
    },
    currentPayInPayListInfo() {
      return this.checkout?.results?.paymentMethods?.filter(i => this.status?.cardPay === i.code && i.enabled) || []
    },
    filterBNPLExclusiveData() {
      if (!this.status?.cardPay) {
        return []
      }
      return this.checkout?.BNPL_PAY_BTN?.filter(item => item.payCode === this.status?.cardPay)
    },
    // 获取分期的金额，如果分期金额没有就是之前的样式
    getInstallMentData() {
      if (!this.status?.cardPay) {
        return ''
      }
      const installmentData = this.checkout?.installmentInfo?.front_show_list?.find(item => item.payment_code === this.status?.cardPay)
      const installmentAmount =  installmentData?.place_order_period_price?.amountWithSymbol
      return installmentAmount || ''
    },
    // 当前支付方式是BNPL，并且需要更改按钮的样式
    getPayBNPLNewBtnLayoutStyle() {
      const paymentCode = this.isClient && getQueryString({ key: 'paymentCode' })
  
      if(this.IS_RW || (paymentCode && !this.status?.cardPay) || !this.isShowNewFooterLayoutStyle || !this.getInstallMentData) {
        return -1
      }

      return !!this.currentPayInPayListInfo?.length ? this.getBnplPlaceOrderStatsByAbt : -1
    },
    // 当前支付方式是BNPL，并且按钮需要变成专属样式（上下布局）
    isShowBNPLExclusiveStyle() {
      return this.getPayBNPLNewBtnLayoutStyle === 2 && this.filterBNPLExclusiveData?.length > 0
    },
    // 当前支付方式是BNPL，并且按钮需要变成专属样式（左右布局）
    isShowBNPLOtherStyle() {
      const limitArr = ['PayPal-GApaypal', 'PayPal-Venmo', 'routepay-card', 'routepay-cardinstallment'].concat(applePayType) // 兜底，防止配置错误
      return this.getPayBNPLNewBtnLayoutStyle == 1 && !limitArr.includes(this.status?.cardPay)
    },
    // 卡路由 卡分期，需要监听这两个参数是否变化
    cardInfo() {
      const cardTokenList = this.status?.cardPay == 'routepay-card' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)?.card_token_list
      const cardInstallTokenList = this.status?.cardPay == 'routepay-cardinstallment' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code && i.enabled)?.card_token_list
      return [this.defaultSelectTokenPay?.id, this.installmentSelectBin, cardTokenList, cardInstallTokenList]
    },
    // 当前选中的卡支付信息
    selectCardInfo(){
      if (['routepay-card'].includes(this.status?.cardPay)) {
        const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)
        const info = currentCardInfo?.card_token_list?.find(f => f?.id == this.defaultSelectTokenPay?.id)
        return currentCardInfo?.shieldAddCard == 0 && info
      }
      return {}
    },
    // 当前选中的卡分期信息
    selectCardInstallmentInfo(){
      if (['routepay-cardinstallment'].includes(this.status?.cardPay)) {
        const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code)
        const info = currentCardInfo?.card_token_list?.find(f => f?.card_bin == this.installmentSelectBin)
        return info
      }
      return {}
    },
    // 卡支付/卡分期
    isShowRouterCardPay() {
      const { webshowTokenFront } = this.checkout?.abtInfo
      const isShowTokenPaymentByAbt = webshowTokenFront?.param?.webshowTokenFrontStatus == '1' //卡路由abt
      const routerCardVisible = isShowTokenPaymentByAbt && !!Object.keys(this.selectCardInfo || {}).length && this.selectCardInfo
      const routerCardInstalment = this.isSelectInstallmentTokenPay && !!Object.keys(this.selectCardInstallmentInfo || {}).length && this.selectCardInstallmentInfo
      return this.getPlaceOrderButtonPayStatusByAbt && (routerCardVisible || routerCardInstalment)
    },
    isSpecialCardpay() {
      if (!this.status?.cardPay) {
        return false
      }
      const limitArr = ['PayPal-GApaypal', 'PayPal-Venmo', 'routepay-card', 'routepay-cardinstallment'].concat(applePayType)
      return !limitArr.includes(this.status?.cardPay)
    },
    isSatisfyBnplHasLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && !!this.getInstallMentData && this.filterBNPLExclusiveData?.length > 0
    },
    isSatisfyBnplHasNotLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && this.isSpecialCardpay && ((!this.getInstallMentData && this.filterBNPLExclusiveData?.length > 0) || !this.filterBNPLExclusiveData?.length)
    },
    // 下单按钮默认文案
    placeOrderTxt () {
      let txt = this.language?.SHEIN_KEY_PWA_15135

      if (this.getPlaceOrderButtonPayStatusByAbt && ['routepay-card', 'routepay-cardinstallment'].includes(this.status?.cardPay)) {
        return (txt || '').toUpperCase()
      }
      if (this.status?.cardPay !== 'routepay-card') {
        txt = (!this.IS_RW && this.checkoutAbtInfo?.placeOrderButtonUpgrade?.param?.placeOrderButtonUpgradeStatus === '1') ? this.language?.SHEIN_KEY_PWA_21233 : this.language?.SHEIN_KEY_PWA_24002
      }
      
      return (txt || '').toUpperCase()
    },
    isQuickShipBtnTips(){
      const { isAllCartsQuickShip, PlaceOrderQuickshipWord } = this.quickShipCheckoutStatus
      return isAllCartsQuickShip && PlaceOrderQuickshipWord == 1
    },
    btnFreeQuickShipTips(){
      const { placeOrderFreeShipping = {} } =  this.checkoutAbtInfo
      const isfree = this.isFreePrice && placeOrderFreeShipping?.param?.placeOrderFreeShippingstatus === '1'
      return isfree && this.isQuickShipBtnTips
        ? 'quickshipFreeAll'
        : this.isQuickShipBtnTips ?  this.quickShipCheckoutStatus?.quickShipLabelText : this.language.SHEIN_KEY_PWA_23851
    },
    paypalBtnID() {
      return this.isDrawerBtn ? 'drawer-paypal-vault-button' : 'paypal-vault-button'
    },
    applePayBtnShow() {
      const apolloInfo = this.checkout?.APPLE_PAY_CODE_LIST
      const applepayDropoffOptimizationAbt = this.checkout?.abtInfo?.ApplepayDropoffOptimization?.p?.ApplepayButtonShow === 'Show'
      if(apolloInfo?.includes(this.status?.cardPay) && applepayDropoffOptimizationAbt){
        return true
      }
      return false
    },
    // paypal签约状态 0 是没有签约 1是签约
    paypalSignStatus() {
      return 'PayPal-GApaypal' == this.status?.cardPay && this.paymentMethodsProps?.paypalGaVault?.radioType == 0
    }
  },
  watch: {
    govTaxPrice(newVal, oldVal){
      if (newVal !== oldVal) {
        this.handleCheckoutBtnWidth()
      }
    },
    totalPrice(newVal, oldVal){
      if (newVal !== oldVal) {
        this.handleCheckoutBtnWidth()
      }
    },
    isOrderTotalOut(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleCheckoutBtnAnimation(newVal)
      }
    },
    'status.cardPay'(newVal, oldVal){
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
      this.animationStripControlFn()
    },
    defaultShipping(){
      this.animationStripControlFn()
    },
    cardInfo(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
    },
    paypalSignStatus() {
      this.handleNormalBtnInfo()
    },
    isUseNewCard() {
      this.handleNormalBtnInfo()
    }
  },
  mounted() {
    this.isClient = true
    if (!this.isOrderTotalOut) {
      this.isUseSAdapterText = true
    }
    this.handleNormalBtnInfo()
    this.handleCheckoutBtnWidth()
    this.animationStripControlFn()
  },
  methods: {
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      sessionStorage.setItem('navtrack_to', 'page_payment')
      sessionStorage.setItem('navtrack_point', Date.now())
      this.$emit('createOrder')
    },
    handleBNPLPlaceText({ installmentColor,  wordColor }) {
      if (this.getInstallMentData && [normalBtnLayoutType.TOP_TEXT_BOTTOM_IMAGE, normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT].includes(this.nomalBtnLayoutType)) {
        return `<span style="color: ${wordColor}">${template(`<span style="color: ${installmentColor}">${this.getInstallMentData}</span>`, this.language.SHEIN_KEY_PWA_30047)}</span>`
      }
      return this.language.SHEIN_KEY_PWA_31565
    },
    // 获取当前非paypal、venmo等常规按钮的信息
    handleNormalBtnInfo() {
      if (this.paypalSignStatus || ['PayPal-Venmo'].includes(this.status?.cardPay) || (this.applePayBtnShow && applePayType.includes(this.status?.cardPay))) {
        return
      }
      const defaultBtnInfo = {
        placeOrderText: this.placeOrderTxt, // 按钮文案
        logo: '', // 支付方式的logo
        brandColor: '', // 品牌色（按钮用于设置背景色，用在BNPL的ABT实验为2的时候）
        subText: '', // 按钮副文案（用在BNPL的ABT实验为2的时候）
      }

      // 卡支付/卡分期
      if (this.isShowRouterCardPay) {
        if (this.isSelectInstallmentTokenPay && this.isUseNewCard) {
          this.nomalBtnLayoutType = normalBtnLayoutType.DEFAULT
          this.normalBtnData = defaultBtnInfo
          return defaultBtnInfo
        }
        const { web_logo, card_no, webLogo, cardNo  } = this.isShowRouterCardPay
        this.nomalBtnLayoutType = typeof window == 'undefined' ? normalBtnLayoutType.DEFAULT : normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        this.normalBtnData =  {
          ...defaultBtnInfo,
          logo: web_logo || webLogo,
          placeOrderText: template(card_no || cardNo, this.language.SHEIN_KEY_PWA_32025),
        }
        return
      }

      if (this.isShowBNPLExclusiveStyle) {
        const { installmentColor, wordColor, brandLogo, brandColor } = this.filterBNPLExclusiveData[0] || {}
        const title = this.currentPayInPayListInfo?.[0]?.title || ''
        this.nomalBtnLayoutType = normalBtnLayoutType.TOP_TEXT_BOTTOM_IMAGE
        this.normalBtnData = {
          ...defaultBtnInfo,
          placeOrderText: this.handleBNPLPlaceText({ installmentColor, wordColor }),
          subText: `${this.language.SHEIN_KEY_PWA_30434} ${title}`,
          logo: brandLogo,
          brandColor,
        }
        return
      }
      
      if (this.isSatisfyBnplHasLure || this.isSatisfyBnplHasNotLure || this.isShowBNPLOtherStyle) {
        if (this.isSatisfyBnplHasNotLure) {
          this.nomalBtnLayoutType = normalBtnLayoutType.LEFT_TEXT_RIGHT_IMAGE
        } else {
          this.nomalBtnLayoutType = typeof window == 'undefined' ? normalBtnLayoutType.DEFAULT : normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        }
        
        this.normalBtnData = {
          ...defaultBtnInfo,
          placeOrderText: this.handleBNPLPlaceText({ installmentColor: '#FA6338', wordColor: '#fff' }),
          logo: this.currentPayInPayListInfo?.[0]?.logo_url || '',
        }
        return
      }
      this.nomalBtnLayoutType = normalBtnLayoutType.DEFAULT
      this.normalBtnData = defaultBtnInfo
    },
    createOrderByApplePay() {
      sessionStorage.setItem('navtrack_to', 'page_payment')
      sessionStorage.setItem('navtrack_point', Date.now())
      appEventCenter.$emit('applepay-create-order')
    },
    handleCheckoutBtnWidth() {
      if (this.isClient && this.$refs?.checkoutActualBtnRef) {
        this.actualBtnWidth = this.$refs?.checkoutActualBtnRef?.offsetWidth + 'px'
      }
    },
    handleCheckoutBtnAnimation(val) {
      if (!this.isClient) {
        return
      }
      if (val) {
        this.$refs.checkoutActualBtnRef?.classList?.remove('checkout-btn-lessen')
        this.$refs.checkoutActualBtnRef?.classList?.add('checkout-btn-overspread')
        this.isCheckoutBtnWidthChange = true
        this.isUseSAdapterText = false
      } else if (this.isCheckoutBtnWidthChange) {
        this.$refs.checkoutActualBtnRef?.classList?.remove('checkout-btn-overspread')
        this.$refs.checkoutActualBtnRef?.classList?.add('checkout-btn-lessen')
        
        setTimeout(()=>{ this.isUseSAdapterText = true }, 500)
      }
    },
    handlerClick() {
      if (this.paypalVenmoBtnShow && !this.applePayBtnShow) {
        this.$refs?.venmoBtnRef?.handleClick()
        return
      }

      if (!this.paypalBtnShow && !this.paypalVenmoBtnShow && !this.applePayBtnShow) {
        this.$refs?.btnRef?.handleClick()
        return
      }
    },
    animationStripControlFn(){
      if(typeof window == 'undefined') return false
      
      this.$nextTick(() => {
        if(this.fristAnimationStrip == 1 || !this.status?.cardPay || !this.defaultShipping?.length || !this.addresslist?.length) return
        this.animationStripControl = true
        this.fristAnimationStrip = 1
        setTimeout(() => {
          this.animationStripControl = false
        }, 900)
      })
    }
  }
}
</script>

<style lang="less">
// 普通的按钮样式
.summary-checkout-btn {
  position: relative;
  width: 240/75rem;
  height: 80/75rem;
  max-width: 300/75rem;
  min-width: 240/75rem;
  .S-button__H80PX {
    padding: 0 16/75rem;
  }
  .paypal-buttons {
    /* stylelint-disable-next-line declaration-no-important */
    min-width: auto !important; // 解决在旧版本的按钮样式下，按钮铺满整屏后支付方式换到pay-pal，pay-pal按钮向右移动到屏幕外问题
  }
}
// 新版本的按钮样式
.checkout-new-layout {
  position: relative;
  flex: 1;
  min-width: 0; // 设置flex是1的时候，消除子元素超过父元素的宽度
  height: 80/75rem;
  .S-button__H80PX {
    padding: 0 24/75rem;
  }
}
.checkout-btn-wrapper {
  position: relative;
  .free-shipping-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -16/75rem;
    right: -8/75rem;
    max-width: calc(~"100% + 0.106rem"); // 0.016rem => 定位right的值，即8/75rem
    padding: 2/75rem 8/75rem;
    background: var(---sui_color_success, #198055);
    z-index: 100; // 这里主要是解决PayPal按钮的遮蔽问题
    &__text {
      max-width: calc(~"100% + 0.466rem"); // 0.466rem => 定位right的值 + 所有的横向间距 + icon的宽度，获得文本的最大宽度
      height: 26/75rem;
      padding: 0 2/75rem;
      margin-left: 8/75rem;
      color: #FFF;
      // line-height: 1.2;
      font-weight: 500;
      .font-dpr(20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .italic-text{
      font-style: italic;
    }
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    right: 0%;
    transform: translate(100%, 220%);
    border: 4.5/75rem solid;
    border-color: #16533A transparent transparent #16533A;
  }
}
// 按钮横向铺满整屏
.checkout-btn-overspread {
  position: absolute !important;
  right: 0;
  top: 0;
  animation: overspreadWholeScreen 0.5s ease forwards;
}
@keyframes overspreadWholeScreen {
  0% {
    width: var(--actual-btn-width);
  }
  100% {
    width: 710/75rem;
  }
}
// 按钮从横向铺满整屏返回到原来的样子
.checkout-btn-lessen {
  position: absolute !important;
  right: 0;
  top: 0;
  animation: zoomToRight 0.5s ease forwards;
}
@keyframes zoomToRight {
  0% {
    width: 710/75rem;
  }
  100% {
    width: 100%;
  }
}
.venmo-btn{
  background-color: #3D95CE;
  border-radius: 6/75rem;
  border-color: transparent;
  .venmo-btn-img{
    height: 26/75rem;
  }
}
.btn-first-layout{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 16/75rem 0 12/75rem;
  &__text{
    width: 100%;
    height: 28/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
  }
  &__logo{
    display: flex;
    align-items: center;
    margin-top: 4/75rem;
  }
  &__logo-img {
    height: 20/75rem;
  }
  &__sub-text {
    width: 100%;
    height: 24/75rem;
    margin-top: 4/75rem;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
    color: var(---sui_color_white, #FFF);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.btn-second-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20/75rem 0;
  &__logo{
    width: 60/75rem;
    height: 100%;
    margin-right: 8px;
    background-color: #FFF;
    border-radius: 4/75rem;
  }
  &__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
  }
}
.btn-third-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20/75rem 24/75rem;
  &__logo{
    width: 60/75rem;
    height: 40/75rem;
    margin-left: 8/75rem;
    border-radius: 4/75rem;
    background-color: #FFF;
  }
  &__text{
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
  }
}

  // 擦亮动画
  @keyframes strip-move_left {
    0% {
      left: -1rem;
    }
    100% {
      left: 100%
    }
  }
  @keyframes strip-move_right {
      0% {
        right: -1rem;
      }
      100% {
        right: 100%
      }
    }

.checkout-btn_container{
  position: relative;
  overflow: hidden;
  // 扫光动画
  .checkout-btn-strip{
    height: 100%;
    position: absolute;
    top: 0;
    left: -1rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png') no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_left 1s 1;
  }
  .checkout-btn-strip_ar{
    height: 100%;
    position: absolute;
    top: 0;
    right: -1rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png') no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_right 1s 1;
    transform: rotate(180deg);

  }
}
</style>
