<template>
  <div>
    <s-sticky
      :type="'fixed'"
      :offset="1.17"
      class="checkout-sticky-notice"
    >
      <div>
        <s-alert
          v-if="communalNotice"
          class="j-checkout-top-notice checkout-top-notice" 
          type="info" 
          :closable="true"
          :show-icon="true"
          :rtl-icon-roate="true"
          :load-more-txt="language.SHEIN_KEY_PWA_15698"
          :description="communalNotice"
          @close="close"
          @onLoadMoreClick="onLoadMoreClick"
          @onInit="changeCartTop"
        />
        <ClientOnly>
          <network-error-alert :description="language.SHEIN_KEY_PWA_31762" />
        </ClientOnly>
      </div>
    </s-sticky>
    <!-- 公告信息弹窗 -->
    <s-dialog
      :visible.sync="isShowNotic"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div v-html="communalNotice"></div>
      <template slot="footer">
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click="noticClosehandle"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { NetworkErrorAlert } from '@shein/sui-mobile'

export default {
  components: {
    ClientOnly,
    NetworkErrorAlert,
  },
  data(){
    return {
      isShowNotic: false
    }
  },
  computed: {
    ...mapState('checkout', ['language']),
    ...mapGetters('checkout', ['communalNotice'])
  },
  mounted () {
    daEventCenter.triggerNotice({ daId: '1-11-1-39' })
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    onLoadMoreClick () {
      daEventCenter.triggerNotice({ daId: '1-11-1-41' })
      this.isShowNotic = true
    },
    close () {
      daEventCenter.triggerNotice({ daId: '1-11-1-40' })
      this.assignState({ noticeHeight: 0 })
    },
    noticClosehandle () {
      this.isShowNotic = false
      daEventCenter.triggerNotice({ daId: '1-11-1-42' })
    },
    changeCartTop () {
      if(typeof window == 'undefined') return

      this.$nextTick(() => {
        const noticeHeight = $('.j-checkout-top-notice').height() || 0
        this.assignState({ noticeHeight })
      })
    },
  },
}
</script>

<style lang="less">
.checkout-sticky-notice {
  z-index: 2;
}
.checkout-top-notice {
  max-height: 2.1rem;
  overflow: hidden;
}
.is-ssr {
  .checkout-top-notice {
    .S-alert__description {
      max-height: 1.38rem;
      overflow: hidden;
      word-break: break-word;
      .font-dpr(24px);
    }
  }
}
</style>
